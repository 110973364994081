import { REACT_APP_VERIFY_TOKEN } from "../config";
import { ErrorResponse, RecaptchaResponse } from "../types";

/**
 * The `verifyToken()` function sends a token the Online Training Support Site
 * backend which then verifies it against Google's reCAPTCHA API with relevant
 * secrets injected into the request.
 * @param token Token generated by reCAPTCHA widget.
 * @returns RecaptchaResponse unless there was an error.
 */
export const verifyToken = async (
  token: string | null
): Promise<RecaptchaResponse | ErrorResponse> => {
  try {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ token }),
    };
    const response = await fetch(REACT_APP_VERIFY_TOKEN, requestOptions);

    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error: error,
    };
  }
};
