import React, { FormEvent, useRef, useState } from "react";
import logo from "./images/FlightSafetyLogo-Reversed.png";
import Required from "./Required";
import ReCaptchaV2, { ReCAPTCHA } from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import {
  REACT_APP_TRAINING_SUPPORT_SITE_KEY_PUBLIC,
  REACT_APP_SEND_MAIL_ROUTE,
} from "./config";
import { verifyToken } from "./utils/verifyToken";
import { getUnsuccessfulResponseMessage } from "./utils/getUnsuccessfulResponseMessage";

interface FormProperties {
  name: string;
  email: string;
  helpType: (typeof HELP_TYPES)[number];
  message: string;
  company?: string;
  phone?: string;
  username?: string;
  token?: string;
}

const HELP_TYPES = [
  "Assessments Attempt Reset",
  "Enrollments for eLearning",
  "Enrollments for LiveLearning",
  "Technical Support",
  "Password Reset/Username Support",
  "General Inquiry",
] as const;

const Contact = () => {
  const [formState, setFormState] = useState<Partial<FormProperties>>({});
  const [formValidated, setFormValidated] = useState(false);

  // sets the button to disabled unless recaptcha is clicked
  const [disabledBtn, setDisabledBtn] = useState<boolean>(true);

  // Google Captcha V2
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const navigate = useNavigate();
  //react-router navigate to the contact page
  const returnHome = () => {
    navigate("/");
  };

  function handleChange<K extends keyof FormProperties>(
    key: K,
    value: FormProperties[K]
  ) {
    setFormState({
      ...formState,
      [key]: value,
    });
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    // Prevent further event propagation
    e.preventDefault();

    if (recaptchaRef.current !== null) {
      const token = recaptchaRef.current.getValue();
      const tokenResponse = await verifyToken(token);
      if (tokenResponse && tokenResponse.success) {
        // Add submitted class to form element
        (e.target as HTMLFormElement).classList.add("submitted");

        const { email, helpType, message, name, company, phone, username } =
          formState as FormProperties;

        const submissionEmail = {
          subject: helpType,
          text: `Type of inquiry: ${helpType} \n Name: ${name} \n Email: ${email}
          ${username ? `\n Username: ${username}` : ""}${
            phone ? `\n Phone: ${phone}` : ""
          }${company ? `\n Company: ${company}` : ""}\n\n Message: ${message}`,
        };

        const mailResponse = await fetch(REACT_APP_SEND_MAIL_ROUTE, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submissionEmail),
        });
        // Testing typing
        console.log(mailResponse);

        if (mailResponse.status >= 200 && mailResponse.status < 300) {
          window.alert(
            "Your request for assistance was successfully submitted."
          );
          setTimeout(returnHome, 1000);
        } else {
          window.alert(
            "Your request could not be completed at this time. Please try again later."
          );
        }
      } else {
        const message = getUnsuccessfulResponseMessage(tokenResponse);
        window.alert(message);
      }
    } else {
      console.log("You must confirm you are not a robot");
    }
  }

  return (
    <div className="backgroundPage backgroundRepeat">
      <div className="header">
        <img src={logo} alt="Flightsafety Logo" className="fslogo" />
      </div>
      <div className="wrapperForm">
        <h1>Contact Us for Assistance</h1>
        <form
          className={formValidated ? "validated" : ""}
          onSubmit={handleSubmit}
        >
          <Required>
            <select
              className="formSelect"
              onChange={(e) => {
                const value = e.target.value;
                handleChange("helpType", value as (typeof HELP_TYPES)[number]);
              }}
              required
            >
              <option value="">How can we help you?</option>
              {HELP_TYPES.map((help) => (
                <option key={help} value={help}>
                  {help}
                </option>
              ))}
            </select>
          </Required>
          <fieldset>
            <legend>Contact Details</legend>
            <div className="gridWrapper">
              <Required className="row1">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("name", value);
                  }}
                  placeholder="Name"
                  required
                  type="text"
                />
              </Required>
              <Required className="row1">
                <input
                  className="formInput"
                  onChange={(e) => {
                    const value = e.target.value;
                    handleChange("email", value);
                  }}
                  placeholder="Email Address"
                  required
                  type="email"
                />
              </Required>
              <input
                className="formInput row1"
                onChange={(e) => {
                  const value = e.target.value;
                  handleChange("username", value);
                }}
                placeholder="Username"
                type="text"
              />
              <input
                className="formInput row2"
                type="text"
                onChange={(e) => {
                  const value = e.target.value;
                  handleChange("phone", value);
                }}
                placeholder="Phone Number"
              />
              <input
                className="formInput row2"
                type="text"
                onChange={(e) => {
                  const value = e.target.value;
                  handleChange("company", value);
                }}
                placeholder="Company Name"
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Message</legend>
            <Required>
              <textarea
                className="formTextArea"
                onChange={(e) => {
                  const value = e.target.value;
                  handleChange("message", value);
                }}
                placeholder={`Please include a description of how we can help. \n\nPlease include as much detail as possible (Course Name(s), Operating System, Web Browser Information, etc.).`}
                required
                rows={8}
              ></textarea>
            </Required>
          </fieldset>
          <div>
            <div>
              <div>Hours of Operation:</div>
              <div style={{ marginBottom: "15px" }}>
                Monday through Friday, 8AM - 5PM US Central, excluding holidays.
              </div>
            </div>
            <div>
              <ReCaptchaV2
                ref={recaptchaRef}
                sitekey={REACT_APP_TRAINING_SUPPORT_SITE_KEY_PUBLIC}
                onChange={() => {
                  setDisabledBtn(false);
                }}
              />
            </div>
          </div>
          <div>
            <button className="returnBtn" onClick={returnHome}>
              Return to Main
            </button>
            <input
              className="submitButton"
              onClick={() => setFormValidated(true)}
              type="submit"
              value="Submit"
              disabled={disabledBtn}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
