const LOCAL =
  window.location.href.includes("localhost") ||
  window.location.href.includes("127.0.0.1");
const DEV = /dev(\.k8s)?\.flightsafety.com/.test(window.location.href);
const QAT = /qat(\.k8s)?\.flightsafety.com/.test(window.location.href);
const UAT = /uat(\.k8s)?\.flightsafety.com/.test(window.location.href);

let REACT_APP_TRAINING_SUPPORT_SITE_KEY_PUBLIC = "";
let REACT_APP_SEND_MAIL_ROUTE = "";
let REACT_APP_VERIFY_TOKEN = "";

if (LOCAL) {
  REACT_APP_TRAINING_SUPPORT_SITE_KEY_PUBLIC = `6LfOLHgjAAAAAC56UXFFpwCHy39T6cevi48S4nNe`;
  REACT_APP_SEND_MAIL_ROUTE = `http://localhost:3010/send-mail`;
  REACT_APP_VERIFY_TOKEN = `http://localhost:3010/verify-token`;
} else if (DEV) {
  REACT_APP_TRAINING_SUPPORT_SITE_KEY_PUBLIC = `6LfOLHgjAAAAAC56UXFFpwCHy39T6cevi48S4nNe`;
  REACT_APP_SEND_MAIL_ROUTE = `https://onlinetraining-backend.dev.k8s.flightsafety.com/send-mail`;
  REACT_APP_VERIFY_TOKEN = `https://onlinetraining-backend.dev.k8s.flightsafety.com/verify-token`;
} else if (QAT) {
  REACT_APP_TRAINING_SUPPORT_SITE_KEY_PUBLIC = `6LcxEpsmAAAAADKhJEBdGE-zPsppMmKy7UY96-QT`;
  REACT_APP_SEND_MAIL_ROUTE = `https://onlinetraining-backend.qat.k8s.flightsafety.com/send-mail`;
  REACT_APP_VERIFY_TOKEN = `https://onlinetraining-backend.qat.k8s.flightsafety.com/verify-token`;
} else if (UAT) {
  REACT_APP_TRAINING_SUPPORT_SITE_KEY_PUBLIC = `6LfNE5smAAAAAF7CZWEu7yZtDTjdvVm3H-ZCatsh`;
  REACT_APP_SEND_MAIL_ROUTE = `https://onlinetraining-backend.uat.k8s.flightsafety.com/send-mail`;
  REACT_APP_VERIFY_TOKEN = `https://onlinetraining-backend.uat.k8s.flightsafety.com/verify-token`;
} else {
  REACT_APP_TRAINING_SUPPORT_SITE_KEY_PUBLIC = `6Lc1FJsmAAAAAKur6U53-ZQpCZcxAJHeq8PDYcNb`;
  REACT_APP_SEND_MAIL_ROUTE = `https://onlinetraining-support.flightsafety.com/api/send-mail`;
  REACT_APP_VERIFY_TOKEN = `https://onlinetraining-support.flightsafety.com/api/verify-token`;
}

export {
  REACT_APP_TRAINING_SUPPORT_SITE_KEY_PUBLIC,
  REACT_APP_SEND_MAIL_ROUTE,
  REACT_APP_VERIFY_TOKEN,
  LOCAL,
  DEV,
  QAT,
  UAT,
};
