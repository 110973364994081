import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
};

const Required = ({ children, className = "" }: Props) => {
  return (
    <div className={`requiredWrapper ${className}`}>
      {children}
      <span className="requiredMark">*</span>
    </div>
  );
};

export default Required;
