import { RECAPTCHA_ERROR } from "../constants";
import { ErrorResponse, RecaptchaResponse } from "../types";

function implementsErrorResponse(payload: unknown): payload is ErrorResponse {
  return (
    typeof payload === "object" &&
    payload !== null &&
    "success" in payload &&
    "error" in payload
  );
}

function implementsRecaptchaResponse(
  payload: unknown
): payload is RecaptchaResponse {
  return (
    typeof payload === "object" &&
    payload !== null &&
    (("success" in payload && "challenge_ts" in payload) ||
      "error-codes" in payload)
  );
}

/**
 * The `getUnsuccessfulResponseMessage()` function provides additional human
 * readable info for token responses that are either not defined due to some
 * error in response from the server or some error in the reCAPTCHA API usage.
 *
 * I don't really like doing it this way but I don't want to keep them waiting.
 * @param response
 */
export function getUnsuccessfulResponseMessage(
  response: RecaptchaResponse | ErrorResponse | undefined
) {
  // Log the response for console troubleshooting.
  console.log(response);

  if (!response)
    return "Something went wrong. The response payload was undefined.";

  if (implementsErrorResponse(response))
    return "Something went wrong. The server failed to handle the request.";

  if (!implementsRecaptchaResponse(response))
    return "Something went wrong. The response payload was malformed.";

  if (response["error-codes"].length)
    return `Something went wrong. The following errors were received:\n${response[
      "error-codes"
    ]
      .map((code) => RECAPTCHA_ERROR[code])
      .join("\n")}`;

  return "Something went wrong. The response payload was valid but no additional errors were provided by reCAPTCHA API.";
}
